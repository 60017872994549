@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon.eot?tbo28t');
  src:  url('assets/fonts/icomoon.eot?tbo28t#iefix') format('embedded-opentype'),
  url('assets/fonts/icomoon.ttf?tbo28t') format('truetype'),
  url('assets/fonts/icomoon.woff?tbo28t') format('woff'),
  url('assets/fonts/icomoon.woff2?tbo28t') format('woff2'),
  url('assets/fonts/icomoon.svg?tbo28t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arobase:before {
  content: "\e900";
}
.icon-map:before {
  content: "\e901";
}
.icon-message:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-user:before {
  content: "\e904";
}

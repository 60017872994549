@import 'mixin';
@import 'icomoon';

/* You can add global styles to this file, and also import other style files */
html, body {
  --main-blue: #009fe3;
  --main-white: #fff;
  --main-dark-gray: #2b3741;
  --max-width: 1200px;

  margin: 0;
  padding: 0;
  color: var(--main-dark-gray);
  font-family: sans-serif;
  scroll-behavior: smooth;
}
.blue {
  color: var(--main-blue);
}
.bg-white {
  background: var(--main-white);
  color: var(--main-blue);
}
.bg-blue {
  background: var(--main-blue);
  color: var(--main-white);
}
.bg-dark-gray {
  background: var(--main-dark-gray);
  color: var(--main-white);
}
h1 {
  font-size: 2.5em;
}
h2 {
  text-align: center;
  font-size: 2.5em;
  margin: 0 auto 60px auto;

  @include media-max(900px) {
    margin-bottom: 20px;
  }
}
.content {
  max-width: var(--max-width, 900px);
  margin: 0 auto;
  padding: 100px 20px;

  @include media-max(900px) {
    padding: 56px 20px;
  }
}


@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0098d9 !important;
}
